<template>
 
    <router-link :to="'/'+$i18n.locale+'/organization/'+orgId+'/products/'+dishId" class="food-card">
      <div class="f-img" v-if="image!==null">
        <img :src="image.thumbnails.medium" alt="" />
      </div>
      <div class="f-img" v-else>
        <img src="../../assets/images/default-img.svg"  alt="img not found :("/>
      </div>
        {{ title }}
      </router-link>
</template>

<script>
export default {
  props: ["image", "title", "dishId", "orgId"],
};
</script>

<style>
</style>