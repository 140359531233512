<template>
  <div class="about-us-page eat-page m-eat-page">
    <section class="section-food">
      <div class="container">
        <div class="section-food-header">
          <div class="row justify-content-between">
            <div class="col-md-6 col-sm-12">
              <div class="text-lg">
                {{ $t("Joylashgan hududingizdagi taomlar") }}
              </div>
              <div class="text-sm">
                {{ $t("text for subfilter, some text") }}
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="section-food-selects">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="my-select">
                      <div class="custom-select">
                        <select
                          v-model="regionId"
                          @change="$store.dispatch('getDistricts', regionId)"
                        >
                          <option value="" disabled>
                            {{ $t("Regionni tanlang") }}
                          </option>
                          <option
                            v-for="region in regions"
                            :key="region.id"
                            :value="region.id"
                          >
                            {{ region.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="my-select my-select-last">
                      <div class="custom-select">
                        <select
                          v-model="districtId"
                          @change="
                            $store.dispatch('filterOrgDis', {
                              regionId: regionId,
                              districtId: districtId,
                            })
                          "
                        >
                          <option value="" selected disabled>
                            {{ $t("Shaharni tanlang") }}
                          </option>
                          <option
                            v-for="district in districts"
                            :key="district.id"
                            :value="district.id"
                          >
                            {{ district.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="food-menu">
            <ul class="bottom-border" v-if="regions.length > 0">
              <li>
                <a href="#!" v-if="regionId !== ''">{{
                  regions.find((el) => el.id == regionId).name
                }}</a>
              </li>
              <li>
                <a href="#!" v-if="districtId !== ''">{{
                  districts.find((el) => el.id == districtId).name
                }}</a>
              </li>
            </ul>
            <div class="row margin-top">
              <div class="col-lg-6 col-xl-6 col-md-10 col-sm-12">
                <div class="my-eat-card">
                  <div class="mye-img" v-if="organization.image">
                    <img :src="organization.image.thumbnails.medium" alt="" />
                  </div>
                  <div class="mye-img" v-else>
                    <img
                      src="../../../assets/images/default-img.svg"
                      alt="Image not found"
                    />
                  </div>
                  <div class="mye-links">
                    <a href="#!" v-if="organization.address !== undefined">
                      <img
                        src="../../../assets/images/icons/loc.svg"
                        alt=""
                      />{{ organization.address.slice(0, 35) + " ..." }}</a
                    >
                    <a href="#!" v-if="false">
                      <img
                        src="../../../assets/images/icons/bag.svg"
                        alt=""
                      />{{ $t("Yetkazib berish xizmati mavjud") }}</a
                    >
                    <a :href="'tel:' + organization.contact" class="phone-link">
                      <img
                        src="../../../assets/images/icons/pho.svg"
                        alt=""
                      />{{ organization.contact }}</a
                    >
                  </div>
                  <div class="title">{{ organization.title }}</div>
                  <div class="sub" v-html="organization.description"></div>
                  <share-2></share-2>
                </div>
              </div>
              <div class="col-lg-5 col-xl-4 col-md-10 col-sm-12">
                <div class="food-menu">
                  <div class="row cus-row" v-if="dishes.length > 0">
                    <div
                      class="col-lg-10 col-md-4 col-sm-6 col-12 custom-col"
                      v-for="dish in dishes"
                      :key="dish.id"
                    >
                      <food-card
                        :title="dish.title"
                        :image="dish.image"
                        :dishId="dish.id"
                        :orgId="organization.id"
                      >
                      </food-card>
                    </div>
                  </div>
                  <div
                    class="more-button"
                    v-if="
                      $store.state.dishCurrentPage <=
                        $store.state.dishPageCount && dishes.length > 0
                    "
                  >
                    <a
                      @click="
                        $store.dispatch('getDishesMore', $route.params.id)
                      "
                      >{{ $t("Koproq") }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <section class="rek-menu">
              <div class="col-md-6 col-sm-12">
                <div class="text-lg">
                  {{ $t("Joylashgan hududingizdagi taomlar") }}
                </div>
                <div class="text-sm">
                  {{ $t("text for subfilter, some text") }}
                </div>
              </div>
              <div class="row" v-if="filteredOrgs.length > 0">
                <restoran-card
                  v-for="item in regionId == ''
                    ? filteredOrgs.slice(0, 6)
                    : filteredOrgs"
                  :key="item.id"
                  :adress="item.address"
                  :legal_name="item.title"
                  :orgId="item.id"
                  :contact="item.contact"
                  :orgLogo="item.image"
                >
                </restoran-card>
              </div>
              <div class="more-button">
                <router-link
                  @click.native="$store.dispatch('allOrganization')"
                  :to="'/' + $i18n.locale + '/organization'"
                >
                  {{ $t("Barchasi") }}
                </router-link>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <section class="au-first">
      <mini-cards></mini-cards>
    </section>
  </div>
</template>

<script>
import foodCard from "../../elements/FoodCard";
import RestoranCard from "../../elements/RestoranCard.vue";
import miniCards from "../../elements/MiniCards";
import { mapState } from "vuex";
import Share2 from "../../elements/Share2.vue";
export default {
  components: {
    foodCard,
    RestoranCard,
    miniCards,
    Share2,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("getOrganization", this.$route.params.id);
    this.$store.dispatch("getDishes", this.$route.params.id);
  },
  methods: {},
  computed: {
    ...mapState([
      "organizations",
      "organization",
      "dishes",
      "regions",
      "districts",
      "filteredOrgs",
    ]),
    regionId: {
      get() {
        return this.$store.state.regionId;
      },
      set(value) {
        this.$store.commit("SET_REGION_ID", value);
      },
    },
    districtId: {
      get() {
        return this.$store.state.districtId;
      },
      set(value) {
        this.$store.commit("SET_DISTRICT_ID", value);
      },
    },
  },
  destroyed() {
    this.$store.commit("RESET_DISH");
  },
};
</script>

<style>
</style>
<style scoped>
.m-eat-page .cus-row {
  justify-content: center;
  max-height: 75vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.rek-menu .row {
  margin: 30px -60px 0 -60px;
}
</style>