<template>
    <div class="app-apple">
        <div class="link-img">
            <ShareNetwork
                network="facebook"
                :url="url"
                title="facebook"
                class="au-s-link"
            >
                <img src="../../assets/images/icons/fcb.svg">
            </ShareNetwork>
            <ShareNetwork
                network="instagram"
                :url="url"
                title="instagram"
                class="au-s-link"
        >
             <img src="../../assets/images/icons/ins.svg" alt="">
        </ShareNetwork>
        <ShareNetwork
                network="telegram"
                :url="url"
                title="telegram"
                class="au-s-link"
        >
             <img src="../../assets/images/icons/tlg.svg" alt="">
        </ShareNetwork>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            url:''
        }
    },
    mounted(){
        this.url = window.location.href
    }
}
</script>

<style>

</style>